<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="md" class="custom-header">
        <ion-title v-if="!search"><strong>Ordainketak</strong></ion-title>

        <ion-buttons slot="end">
          <ion-button @click="searchBox">
            <ion-icon
              v-if="!search"
              slot="icon-only"
              :icon="searchOutline"
            ></ion-icon>
            <ion-icon v-else slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-searchbar
          v-if="search"
          :value="searchTerm"
          @ionInput="searchTerm = $event.target.value"
          show-cancel-button="never"
          mode="md"
          placeholder="Ordainketak bilatu"
        >
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content color="tertiary">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content />
      </ion-refresher>


      <errors v-if="!loading && items && !items.length"
        :options="{
          title: 'Adi!',
          message: 'Ez da ordainketarik aurkitu.',
          image: true
        }"
      />

      <ion-list
        v-if="!loading"
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
      >
        <ion-item
          v-for="item in items"
          :key="item"
          color="transparent"
          lines="full"
          @click="editPayment(item)"
        >
          <ion-badge color="light" slot="start">#{{ item.id }}</ion-badge>

          <ion-label>
            <strong>{{ item.name }}</strong>
            <p>{{ item.client.name }}</p>
          </ion-label>

          <ion-chip v-if="item.paid == 1" color="success">
            <ion-icon :icon="card" v-if="item.stripe_id != 0"></ion-icon>
            <ion-icon :icon="checkmarkCircle" v-else></ion-icon>

            <ion-label
              ><strong>{{ item.amount }}€</strong></ion-label
            >
          </ion-chip>

          <ion-chip v-else color="danger">
            <ion-icon :icon="closeCircle"></ion-icon>
            <ion-label
              ><strong>{{ item.amount }}€</strong></ion-label
            >
          </ion-chip>
        </ion-item>
      </ion-list>

      <ion-list
        v-else
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
        lines="full"
        inset="false"
      >
        <ion-item
          v-for="item in 20"
          :key="item"
          color="transparent"
          lines="full"
        >
          <ion-label
            class="animate__animated animate__fadeInDown animate__faster"
          >
            <h3>
              <ion-skeleton-text
                animated
                style="width: 60%"
              ></ion-skeleton-text>
            </h3>

            <p>
              <ion-skeleton-text
                animated
                style="width: 40%"
              ></ion-skeleton-text>
            </p>
          </ion-label>

          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </ion-item>
      </ion-list>

      <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  modalController,
} from "@ionic/vue";

import { useStore } from "vuex";
import { ref, onMounted, watch, computed } from "vue";
import PaymentForm from "@/components/forms/PaymentForm.vue";
import Errors from '@/components/Errors.vue'

import axios from "axios";

import {
  arrowBackOutline,
  ellipsisHorizontal,
  personAddOutline,
  searchOutline,
  closeOutline,
  checkmarkCircle,
  closeCircle,
  circles,
  card,
} from "ionicons/icons";
export default {
  name: "Payments",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    Errors
  },
  setup() {
    const loading = ref(true);
    const isDisabled = ref(false);
    const store = useStore();
    //const perPage = ref(50);
    const pageNumber = ref(1);
    const search = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    };
    const items = ref([]);
    const searchTerm = ref(null);
    const api = ref(process.env.VUE_APP_API);

    const getPayments = computed(() => store.state.getPayments);
    const getPaymentsReset = computed(() => store.state.getPaymentsReset);

    const searchBox = () => {
      search.value = !search.value;
      if (!search.value) {
        searchTerm.value = "";
        handleSearch();
      }
    };

    onMounted(() => {
      items.value = getPayments.value;
      loading.value = false;
    });

    watch(getPayments, (value) => {
      if (pageNumber.value == 1) items.value = [];

      value.forEach((res) => {
        items.value.push(res);
      });
      loading.value = false;
      store.commit("_RESET_PAYMENTS", false);
    });

    watch(getPaymentsReset, (value) => {
      if (value) {
        pageNumber.value = 1;
        store.dispatch("_getPayments", pageNumber.value);
      }
    });

    // searchbar
    watch(searchTerm, (value) => {
      if (value.length > 0 && value.length < 4) return;
      handleSearch();

      if (value.length == 0) {
        pageNumber.value = 1;
        store.dispatch("_getPayments", pageNumber.value);
      }
    });

    const doRefresh = (ev) => {
      pageNumber.value = 1;
      store
        .dispatch("_getPayments", 1)
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    const loadData = (ev) => {
      if (pageNumber.value == 1) pageNumber.value = 2;
      store
        .dispatch("_getPayments", pageNumber.value)
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    const editPayment = async (payment) => {

      const modal = await modalController.create({
        component: PaymentForm,
        componentProps: {
          payment: payment,
          client: payment.client
        },
      });
      return modal.present();
    };

    const handleSearch = () => {
      if (searchTerm.value.length < 4) return true;

      axios
        .get(`${api.value}payments/search/${searchTerm.value}`)
        .then((res) => {
          items.value = res.data.result;
        })
        .catch(() => {});
    };

    return {
      loading,
      isDisabled,
      toggleInfiniteScroll,
      loadData,
      items,
      doRefresh,
      arrowBackOutline,
      ellipsisHorizontal,
      personAddOutline,
      searchOutline,
      search,
      searchTerm,
      searchBox,
      handleSearch,
      closeOutline,
      editPayment,
      checkmarkCircle,
      closeCircle,
      circles,
      card,
    };
  },
};
</script>

<style lang="scss">
@import "@/theme/theme.scss";
.toolbar-title-default {
  box-shadow: none !important;
}
</style>
